import { observable, action, computed, makeObservable } from 'mobx'
import { uuid } from 'utils/uuid'

import { CHANNEL_EVENT_TYPES, STREAM_STATES } from 'constants/liveShoppingAmplify.constants'

import { amplifyInit, mutation, subscribe } from 'utils/amplify.utils'
import { storageHasItem, getItemFromStorage, setItemToStorage } from 'utils/sessionStorage.utils'
import { dateTimeService } from '@elo-kit/utils/dateTime.utils'
import { TIME_FORMATS } from '@elo-kit/constants/dateTime.constants'
import { escapeQuotesAndSlashes } from 'utils/nameStyle.utils'

export class LiveShoppingStore {
  publicToken = 'live-shopping-public'

  @observable streamState = STREAM_STATES.buffering
  @observable viewerCount = 0
  @observable channelId = null
  @observable subscriptions = {
    events: null,
    chat: null,
  }
  @observable messages = []
  @observable user = storageHasItem('chat_user') ? getItemFromStorage('chat_user') : ''

  @computed get isStreamOnline() {
    return this.streamState === STREAM_STATES.online
  }

  constructor() {
    makeObservable(this)
    amplifyInit()
  }

  @action setStreamState = (value) => (this.streamState = value)
  @action setViewersCount = (value) => (this.viewerCount = value)
  @action setChannelId = (value) => (this.channelId = value)
  @action initStreamInformation = (data) => {
    const { liveViewers, streamState, channelArn } = data
    this.setViewersCount(liveViewers)
    this.setStreamState(streamState)
    this.setChannelId(channelArn)
    this.initChatData(channelArn)
  }
  @action clearStreamInformation = () => {
    this.setViewersCount(0)
    this.setStreamState(STREAM_STATES.offline)
    this.setChannelId(null)
  }

  @action addMessage = (value) => {
    this.messages = [...this.messages, value]
    this.updateStorageMessages(this.messages)
  }

  @action setChatUser = (user) => {
    this.user = user
    this.updateChatUsers(user)
  }

  updateStorageMessages = (messages) => {
    const storedMessages = storageHasItem('chat_messages') ? getItemFromStorage('chat_messages') : {}

    storedMessages[this.channelId] = messages
    setItemToStorage('chat_messages', storedMessages)
  }

  updateChatUsers = (user) => {
    const storageUsers = storageHasItem('chat_users') ? getItemFromStorage('chat_users') : {}

    storageUsers[this.channelId] = user
    setItemToStorage('chat_users', storageUsers)
  }

  initChatData(channelArn) {
    const storedMessages = storageHasItem('chat_messages') ? getItemFromStorage('chat_messages') : {}
    const storageUsers = storageHasItem('chat_users') ? getItemFromStorage('chat_users') : {}

    if (Object.hasOwn ? Object.hasOwn(storedMessages, channelArn) : storedMessages[channelArn]) {
      this.messages = storedMessages[channelArn]
    } else {
      this.messages = []
      storedMessages[channelArn] = []
      setItemToStorage('chat_messages', storedMessages)
    }

    if (Object.hasOwn ? Object.hasOwn(storageUsers, channelArn) : storageUsers[channelArn]) {
      this.user = storageUsers[channelArn]
    } else {
      this.user = ''
      storageUsers[channelArn] = ''
      setItemToStorage('chat_users', storageUsers)
    }
  }

  handleChannelEvent = (e) => {
    const { eventName, data, channelArn } = e.value.data.onPublishEvent || {}
    switch (eventName) {
      case CHANNEL_EVENT_TYPES.streamStart: {
        this.setStreamState(STREAM_STATES.online)
        break
      }
      case CHANNEL_EVENT_TYPES.streamEnd: {
        this.setStreamState(STREAM_STATES.offline)
        break
      }
      case CHANNEL_EVENT_TYPES.viewersCountUpdate: {
        const { viewers } = JSON.parse(data) || {}
        this.setStreamState(STREAM_STATES.online)
        this.setViewersCount(viewers)
        break
      }
      case CHANNEL_EVENT_TYPES.buffering: {
        this.setStreamState(STREAM_STATES.buffering)
        break
      }
      default: {
        this.initStreamInformation({
          channelArn: channelArn,
          streamState: STREAM_STATES.buffering,
          liveViewers: this.viewerCount,
        })
        break
      }
    }
  }

  handleChatMessages = (e) => {
    const { uuid: id, sender_name: senderName, message, role } = e.value.data.onPublishChatMessageV2 || {}
    const messageExist = this.messages.find((message) => message.id === id)

    if (!messageExist) {
      this.addMessage({
        id: id,
        nickname: senderName,
        message: message,
        role: role,
        time: dateTimeService().format(TIME_FORMATS.HHmm),
      })
    }
  }

  listenChannelEvents = () => {
    this.subscriptions.events = subscribe(
      this.channelId,
      this.publicToken,
      'onPublishChannelEvent',
      this.handleChannelEvent
    )
  }

  listenChatMessages = () => {
    this.subscriptions.chat = subscribe(
      this.channelId,
      this.publicToken,
      'onPublishChatMessage',
      this.handleChatMessages
    )
  }

  unlListenChannelEvents = () => {
    this.subscriptions.events.unsubscribe()
  }

  unlListenChat = () => {
    this.subscriptions.chat.unsubscribe()
  }

  sendChatMessage = (nickname, message, role) => {
    const messageId = uuid()

    this.addMessage({
      id: messageId,
      nickname: nickname,
      message: message,
      role: role,
      time: dateTimeService().format(TIME_FORMATS.HHmm),
    })

    const args = [messageId, nickname, escapeQuotesAndSlashes(message), role]

    mutation(this.channelId, this.publicToken, 'sendMessage', args)
  }
}

export default new LiveShoppingStore()
