import { API, graphqlOperation, Amplify } from 'aws-amplify'

import { getElopageConfig } from 'utils/elopageConfig.utils'

const onPublishChannelEvent = (channel) => `subscription OnPublishChannelEvent {
  onPublishEvent(channelArn: "${channel}") {
    eventName
    channelArn
    data
  }
}`

const onPublishChatMessage = (channel) => `subscription onPublishChatMessageV2 {
  onPublishChatMessageV2(channelArn: "${channel}") {
    uuid
    channelArn
    sender_name
    message,
    role
  }
}`

const sendMessage = (channel, messageId, sender_name, message, role) => `
  mutation ChatMutation {
    publishChatMessageV2(uuid: "${messageId}", channelArn: "${channel}", sender_name: "${sender_name}", message: "${message}", role: "${role}" ) {
      uuid
      channelArn
      sender_name
      message,
      role
    }
  }
`

const SUBSCRIPTIONS = {
  onPublishChannelEvent,
  onPublishChatMessage,
}

const MUTATIONS = {
  sendMessage,
}

export const amplifyInit = () => {
  const amplifyConfig = getElopageConfig('amplifyConfig')
  Amplify.configure(amplifyConfig)
}

export const subscribe = (id, token, eventName, callback) => {
  if (!SUBSCRIPTIONS[eventName]) return

  const subscription = API.graphql(graphqlOperation(SUBSCRIPTIONS[eventName](id), {}, token)).subscribe({
    next: (evt) => callback(evt),
  })

  return {
    unsubscribe: subscription.unsubscribe.bind(subscription),
  }
}

export const mutation = (id, token, eventName, args) => {
  if (!MUTATIONS[eventName]) return

  API.graphql(graphqlOperation(MUTATIONS[eventName](id, ...args), {}, token))
}
