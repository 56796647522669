export const CHANNEL_EVENT_TYPES = {
  viewersCountUpdate: 'ViewerCountUpdate',
  streamStart: 'Stream Start',
  sessionCreated: 'Session Created',
  sessionEnded: 'Session Ended',
  streamEnd: 'Stream End',
  buffering: 'Buffering',
}

export const STREAM_STATES = {
  online: 'online',
  offline: 'offline',
  buffering: 'buffering',
  failure: 'failure',
}

export const STREAM_STATUS_VIEW_TYPES = {
  withBackground: 'withBackground',
  withoutBackground: 'withoutBackground',
}
